@use 'scss/index.scss' as *;

.cardContainer {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    background: white; // to remove

    &:hover {
        transform: scale(1.03);
    }
}

.link {
    display: grid;
    gap: 1rem;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    justify-items: center;
    color: $black;

    &:hover {
        color: $blue;
    }
}

.image {
    max-height: 200px;
    width: auto;
    max-width: 100%;
}

.badgesContainer {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.newOffer {
    color: $white;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 2rem;
    background: linear-gradient(to right, #80bd1f, #62960e);
}

.sold {
    color: $white;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 2rem;
    background: linear-gradient(to right, #f44336, #d32f2f);
}

.reserved {
    color: $white;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 2rem;
    background: linear-gradient(to right, #ff9800, #f57c00);
}

.content {
    padding: 1rem;
    display: grid;
    gap: 0.5rem;
}

.title {
    font-weight: 500;
    font-size: 1.25rem;
    transition: $transition-default;
}

.description {
    color: rgba($black, 0.9);
    font-size: 0.9rem;
    line-height: 1.2rem;
}
