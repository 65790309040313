@use 'scss/index' as *;

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        height: fit-content;
        border: 1px solid $gray;
    }

    .title {
        font-weight: 500;
        padding: 1rem;
        background: $gray-gradient;
        font-size: 0.9rem;
    }

    .product {
        height: 160px;
        padding: 1rem 0.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        cursor: pointer;
        transition: $transition-default;

        &:hover {
            background: rgba($gray, 0.4);
        }
    }

    .productName {
        font-weight: 500;
        font-size: 0.9rem;
        display: grid;
        align-items: center;
        color: $black;
    }

    .productImage {
        max-height: 120px;
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}
