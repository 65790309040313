@use 'scss/index' as *;

.modal {
    display: grid;
    align-items: center;
    justify-items: center;
}

.form {
    position: relative;
    max-height: 100vh;
    background: $white;
    padding: 2rem;
    display: grid;
    grid-auto-rows: fit-content(100%);
    gap: 1rem;
    height: 100vh;
    max-width: 700px;
    align-items: flex-start;
    overflow: auto;
}

.closeButton {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 2rem;
    color: $black;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.title {
    font-weight: 500;
    // text-align: center;
    margin-bottom: 1rem;
}

.submitButton,
.title,
.problemDescriptionInput,
.checkboxLabel {
    grid-column: 1 / 3;
}

.accordion {
    grid-column: 1 / 3;
}

.alert {
    position: fixed;
    z-index: $z-index-popup;
    right: 2rem;
    bottom: 2rem;
}

.timeInputsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
    gap: 1rem;
}

.contactCheckbox {
    grid-column: 1 / 4;
}

.timeInput {
    width: 100%;
}

@media screen and (min-width: $breakpoint-tablet) {
    .form {
        height: fit-content;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        width: fit-content;
    }

    .timeInputsContainer {
        flex-direction: row;
        gap: 1rem;
        max-width: 100%;
        overflow: auto;
    }

    .timeInput {
        width: auto;
    }
}
