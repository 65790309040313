@use 'scss/index' as *;

.card {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    background: white;
    height: fit-content;

    &:hover {
        transform: scale(1.03);

        & .title {
            color: $blue;
        }
    }

    .link {
        display: grid;
        grid-template-rows: 250px auto;
        justify-items: center;
        gap: 1rem;
        padding: 1rem;
        color: $black;

        &:hover {
            & .title {
                color: $blue;
            }
        }
    }

    .emptyBadge {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
        color: $white;
        font-size: 0.75rem;
        padding: 0.5rem;
        border-radius: 2rem;
        background: $black;
    }

    .imageContainer {
        width: 100%;
        height: 100%;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .title {
        font-weight: 500;
        font-size: 1.25rem;
        text-align: center;
        transition: $transition-default;
    }

    .description {
        color: rgba($black, 0.9);
    }

    &.emptyCategory {
        &:hover {
            transform: none;
            & .title {
                color: $black;
            }
        }

        opacity: 0.5;

        .link {
            cursor: default;
            pointer-events: none;
        }
    }
}
