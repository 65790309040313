@use 'scss/index' as *;

.dialogContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background: #fff;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.dialogTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.searchModeContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.searchList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow: auto;
    height: 100%;

    @media (pointer: fine) {
        padding-right: 0.5rem;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
            background-color: #555;
        }
    }
}

.searchListItem {
    border-bottom: 1px solid $gray;

    &:last-child {
        border-bottom: none;
    }
    &:hover {
        color: $blue;

        &:last-child {
            border-radius: 0 0 8px 8px;
        }
    }
}

.searchListItemLink {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: inherit;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid $blue;
        border-radius: $border-radius;
    }
}

.image {
    height: 75px;
    width: 75px;
    object-fit: contain;
}

.toggleButton {
    display: flex;
    gap: 0.5rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .dialogContainer {
        width: 600px;
        border-radius: $border-radius;
        height: 90vh;
    }
}
