@use 'scss/index' as *;

.container {
    display: grid;
    gap: 2rem;

    p {
        line-height: 1.5rem;
    }
}

.header {
    background: $main-gradient;
    color: $white;
    padding: 1rem;
    border-radius: $border-radius;
    font-weight: 500;
    font-size: 2rem;
    box-shadow: $box-shadow;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        // grid-column: 2 / -1; change when accessories are added
        grid-column: 1 / -1;

        &.skipSidebar {
            grid-column: 1 / -1;
        }
    }
}
