@use 'scss/index' as *;

.modal {
    display: grid;
    align-items: center;
    justify-items: center;
}

.container {
    border-radius: $border-radius;
    background: $white;
    box-shadow: $box-shadow;
    padding: 2rem;
    min-width: 300px;
    max-width: 600px;
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
}

.title {
    font-size: 1.5rem;
    font-weight: 500;
    color: $black;
    text-align: center;
}

.filesList {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    justify-items: center;
    gap: 1.5rem;
}

.file {
    width: 100%;
    display: grid;
    justify-items: center;
    gap: 1rem;
    color: $black;
    cursor: pointer;

    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .filesList {
        grid-template-columns: repeat(4, minmax(100px, 1fr));
    }
}
