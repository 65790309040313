@use 'scss/index.scss' as *;

.offersList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
}

.noOffersContainer {
    display: grid;
    gap: 2rem;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.noOffersTitle {
    font-weight: 500;
    font-size: 2rem;
}
