@use 'scss/index.scss' as *;

.container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    row-gap: 4rem;
    column-gap: 2rem;
}

.logoContainer {
    flex: 0 0 250px;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
}

.logo {
    max-height: 100px;
    max-width: 100%;
}
