@use 'scss/index.scss' as *;

// .container {
//     display: grid;
//     justify-content: center;
//     align-items: center;
//     grid-template-columns: minmax(250px, 500px);
//     row-gap: 4rem;
//     column-gap: 4rem;
// }

// @media screen and (min-width: $breakpoint-desktop) {
//     .container {
//         row-gap: 4rem;
//         grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
//     }
// }

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 4rem;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        flex-direction: row;
    }
}
