@use 'scss/index.scss' as *;

.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.name {
    font-weight: 500;
}

.info {
    & p {
        color: rgba(48, 48, 65, 0.8);
        line-height: 1.5rem;
    }
}
