@use 'scss/index.scss' as *;

.offerCard {
    flex: 0 0 250px;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 24px solid transparent;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    color: $black;
    transition: $transition-default;
    -webkit-transition: $transition-default;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: -24px;
        top: -24px;
        transform: scale(1.075);
        border-radius: inherit;
        border: 24px solid transparent;
        transition: inherit;
        -webkit-transition: inherit;
    }

    &:hover {
        background: $gray-accent;
        border: 24px solid transparent;
    }

    &.left::after {
        transform: rotate(-45deg) scale(1.075);
    }

    &.right::after {
        transform: rotate(45deg) scale(1.075);
    }

    &:hover.dark::after {
        border-top: 24px solid $blue-dark;
    }

    &:hover.light::after {
        border-top: 24px solid $blue-light;
    }
}

.content {
    display: grid;
    align-content: space-between;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0 0 $border-radius $border-radius;
}

.image {
    width: 100%;
    height: 120px;
    // height: 100%;
    // width: 175px;
    margin: auto;
    border-radius: $border-radius $border-radius 0 0;
}

.title {
    font-weight: 500;
    transition: $transition-default;
    font-size: 1.5rem;
    text-align: center;
}

.description {
    height: 100%;
    margin-bottom: 1rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .offerCard {
        flex: 0 0 350px;
        width: 350px;
        height: 350px;
    }

    .image {
        height: 175px;
    }
}
