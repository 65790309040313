@use 'scss/index.scss' as *;

$page-height: 95vh;

.container {
    width: 100%;
    height: calc($page-height - 90px);
    position: relative;
    display: grid;
    align-items: flex-end;
    justify-items: center;
}

.image,
.video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: inherit;
    background-size: cover;
}

.image {
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.video {
    filter: brightness(0.8);
    object-fit: cover;
}

.textContainer,
.textContainerMobile {
    padding: 1rem 1rem 10% 1rem;
    position: absolute;
    color: $white;
    display: grid;
    gap: 1.5rem;
    animation: textAppearLeft forwards 1s;
}

.imageOverText {
    max-width: 100%;
}

.title {
    position: relative;
    letter-spacing: 1px;
    font-size: 1.75rem;
    font-weight: 500;
}

.description {
    font-size: 1.2rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .container {
        align-items: center;
        justify-items: left;
    }

    .textContainer,
    .textContainerMobile {
        padding: 4rem 0;
        margin-left: 5vw;
    }

    .title {
        font-size: 4rem;

        &::before {
            position: absolute;
            content: '';
            display: block;
            background: $blue-gradient-bottom;
            height: 100%;
            max-height: 78px;
            width: 4px;
            left: -1rem;
            top: 0;
        }
    }

    .description {
        font-size: 1.5rem;
    }

    .ctaButton {
        width: 200px;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        height: calc($page-height - 90px - 55px);
    }

    .textContainer {
        margin-left: 10vw;
    }

    .textContainerMobile {
        display: none;
    }
}

@keyframes textAppearLeft {
    0% {
        opacity: 0;
        transform: translateX(-8rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
