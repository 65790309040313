@use 'scss/index.scss' as *;

.container {
    display: grid;
    gap: 1.5rem;
}

.pageDescription {
    display: grid;
    gap: 1rem;
}

.title {
    font-weight: 500;
    font-size: 2rem;
    background: $main-gradient;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    color: $white;
    padding: 1rem;
}

.description {
    line-height: 1.5rem;
    color: hsl(0, 0%, 35%);
    padding: 0.5rem 1rem;
}

.filtersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray;
    border-radius: $border-radius;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 4px;
    padding: 1rem 1.5rem;
}

.displayedProductsNumber {
    display: none;
}

.productsList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.returnButtonContainer {
    margin: 0 auto;
}

@media screen and (min-width: $breakpoint-tablet) {
    .displayedProductsNumber {
        display: block;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        // grid-template-columns: repeat(12, 1fr);
    }

    .pageDescription {
        grid-column: 1 / 13;
    }

    .filtersContainer {
        grid-column: 1 / 13;
        grid-row: 2;
    }

    .productsList {
        grid-column: 1 / 13;
    }

    .returnButtonContainer {
        grid-column: 1 / 13;
    }
}
