@use 'scss/index' as *;

.container {
    display: grid;
    gap: 2rem;
}

.categoriesList {
    display: grid;
    list-style: none;
    gap: 1px;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.returnButtonContainer {
    margin: 2rem auto;
}

.description {
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    background: white;
    line-height: 1.6;
    font-size: 1.1rem;
}

@media screen and (min-width: $breakpoint-desktop) {
    .description {
        padding: 2rem;
    }
}
