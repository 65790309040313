@use 'scss/index' as *;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    letter-spacing: 0.25px;
}

.app {
    background: $white;
    color: $black;
}
