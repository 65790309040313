@use 'scss/index' as *;

.layout {
    width: 100%;
    background: $white;

    &__content {
        padding-top: 100px;
        min-height: 100vh;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .layout {
        &__content {
            padding-top: 145px;
        }
    }
}
