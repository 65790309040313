@use 'scss/index' as *;

.container {
    display: grid;
    gap: 2rem;
    height: 100%;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        grid-template-columns: 3fr 5fr 4fr;

        &.noSidebar {
            grid-template-columns: 7fr 5fr;
        }
    }
}
