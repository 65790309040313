@use 'scss/index.scss' as *;

$navbar-height: 100px;

.navbar {
    background: $white;
    height: $navbar-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    z-index: $z-index-fixed;
    box-shadow: 0 2px 4px 0 rgba($black, 0.2);

    &::after {
        content: '';
        display: block;
        position: absolute;
        background: $blue-gradient-vertical;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
    }
}

.logoImg,
.logo {
    // height: 100%;
    height: 60px;
    max-width: 230px;
}

.logo {
    padding-left: 1rem;
}

.linksList {
    display: none;
    list-style: none;
}

.link {
    list-style: none;
    cursor: pointer;

    &.noClick {
        cursor: default;
    }
}

.linkDropdownIcon {
    margin-left: 0.5rem;
}

.mobileBars {
    font-size: 2rem;
    padding: 1rem;
    color: $black;
    width: 24px;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.dropdownItem {
    width: 150px;
}

@media screen and (min-width: $breakpoint-mobile) {
    .navbar {
        padding: 0 1rem;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .navbar {
        height: 90px;
        padding: 1rem 2rem;
        top: 55px;
    }

    .logoImg,
    .logo {
        padding: 0;
        max-width: none;
    }

    .linksList {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .linksListItem {
        position: relative;
    }

    .link {
        padding: 0.5rem;
        color: $black;
        text-transform: uppercase;
        transition: $transition-default;
        position: relative;
        white-space: nowrap;
        font-size: 1.2rem;

        &:hover {
            color: $blue;
        }

        &.active {
            color: $blue;
        }

        &.selected::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 0.25rem;
            width: 90%;
            height: 2px;
            background: $blue-gradient-vertical;
        }
    }

    .mobileBars {
        display: none;
    }
}
