@use 'scss/index.scss' as *;

.section {
    padding: 4rem 2rem;
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__title {
        font-weight: 500;
        text-align: center;
        width: fit-content;
        margin: 0 auto 4rem auto;
        position: relative;

        &::before {
            content: '';
            display: block;
            border-left: 4px solid $blue;
            border-bottom: 4px solid $blue;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -1rem;
            bottom: -0.5rem;
        }

        &::after {
            content: '';
            display: block;
            border-right: 4px solid $blue;
            border-top: 4px solid $blue;
            width: 20px;
            height: 20px;
            position: absolute;
            right: -1rem;
            top: -0.5rem;
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .section {
        padding: 4rem 2rem;
    }
}
