.badge {
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 2rem;
    text-align: center;
}

.new {
    background: linear-gradient(to right, #80bd1f, #62960e);
}

.sold {
    background: linear-gradient(to right, #f44336, #d32f2f);
}

.reserved {
    background: linear-gradient(to right, #ff9800, #f57c00);
}
