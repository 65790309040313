@use 'scss/index' as *;

.container {
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: calc(100vh - 145px - 5rem);
}

.content {
    padding: 1rem;
    display: grid;
    justify-items: center;
    gap: 2rem;
    height: fit-content;
}

.title {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

@media screen and (min-width: $breakpoint-tablet) {
    .title {
        font-size: 3rem;
    }
}
