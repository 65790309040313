@use 'scss/index.scss' as *;

.container {
    display: grid;
    gap: 1.5rem;
}

.pageDescription {
    display: grid;
    gap: 1rem;
}

.title {
    font-weight: 500;
    font-size: 2rem;
    background: $main-gradient;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    color: $white;
    padding: 1rem;
}

.description {
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    line-height: 1.6;
    background: white;
    font-size: 1.1rem;
    width: 100%;
}

.filtersContainer {
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray;
    border-radius: $border-radius;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 4px;
    padding: 1rem 1.5rem;
}

.displayedProductsNumber {
    display: none;
}

.productsList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.returnButtonContainer {
    margin: 2rem auto;
}

.noProductsFoundText {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    grid-column: -1 / 1;
}

@media screen and (min-width: $breakpoint-tablet) {
    .displayedProductsNumber {
        display: block;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .description {
        grid-column: 1 / 13;
    }

    .pageDescription {
        grid-column: 1 / 13;
    }

    .filtersContainer {
        grid-column: 1 / 13;
        grid-row: 2;
    }

    .productsList {
        grid-column: 1 / 13;
    }

    .returnButtonContainer {
        grid-column: 1 / 13;
    }
}
