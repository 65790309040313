@use 'scss/index' as *;

.container {
    color: $black;
    display: grid;
    gap: 1rem;
    height: fit-content;
    border-bottom: 1px solid $gray;
}

.image {
    width: 100%;
    max-height: 200px;
    margin: auto;
    object-fit: contain;
}

.textContainer {
    padding: 1.5rem;
    display: grid;
    gap: 1rem;
}

.titleLink {
    color: $font-primary;
}

.title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.date {
    cursor: pointer;
}

.description {
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

@media screen and (min-width: $breakpoint-desktop) {
}
