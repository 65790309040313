@use 'scss/index.scss' as *;

.socialMediaLinks {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.link {
    color: $white;

    &.black {
        color: $black;
    }

    &.white {
        color: $white;
    }
}

.icon {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}
