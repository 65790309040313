@use 'scss/index' as *;

.modal {
    display: grid;
    align-items: center;
    justify-items: center;
}

.formContainer {
    border-radius: $border-radius;
    background: $white;
    box-shadow: $box-shadow;
    padding: 2rem;
    display: grid;
    gap: 2rem;
    overflow-y: auto;
    max-height: 100vh;
    max-width: 700px;
}

.form {
    display: grid;
    row-gap: 1.5rem;
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

.title {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
}

.detailsInput {
    grid-column: 1 / 3;
}

.checkbox {
    font-size: 0.75rem;
}

.submitButton,
.checkboxLabel {
    grid-column: 1 / 3;
}

.alert {
    position: fixed;
    z-index: $z-index-popup;
    right: 2rem;
    bottom: 2rem;
}

.dropdownItem {
    text-transform: capitalize;
}
