@use 'scss/index.scss' as *;

.footer {
    background-color: $black;
    color: $white;
}

.footerTop {
    display: grid;
    gap: 2rem;
    padding: 2rem 1rem;
}

// .aboutCompany {
//     display: flex;
//     flex-direction: column;
//     gap: 1.5rem;
//     line-height: 1.25rem;
//     max-width: 300px;
// }

// .logo {
//     width: 100px;
// }

// .companyDescription {
//     font-size: 0.9rem;
// }

.linksContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.linksTitle {
    font-weight: 500;
    font-size: 1.5rem;
}

.linkList {
    display: grid;
    gap: 1rem;
    list-style-position: inside;
    list-style-type: none;
}

.linkAnchor {
    color: inherit;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.newsletter {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 300px;
}

.newsletterForm {
    display: grid;
    // grid-template-columns: 1fr;
}

.newsletterTitle {
    font-size: 1.5rem;
    font-weight: 500;
}

.newsletterInput {
    transition: $transition-default;
    background: $white;
}

.newsletterSubmit {
    font-size: 1rem;
    height: 100%;
    border-radius: 0px !important;
}

.copyright {
    padding: 2rem 0;
    text-align: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        background: $white;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .footerTop {
        grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    }

    .newsletterForm {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .newsletterSubmit {
        border-radius: 0 8px 8px 0 !important;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .footerTop {
        max-width: 1400px;
        margin: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 4rem 2rem 2rem 2rem;
    }

    .copyright {
        padding: 2rem 0;
        grid-column: 1 / 4;

        &::before {
            content: '';
            display: block;
            background: $white;
            height: 1px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
