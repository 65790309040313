@use 'scss/index' as *;

.carouselContainer {
    display: none;
}

.dots {
    bottom: 1rem !important;
    left: 75% !important;
}

@media screen and (min-width: $breakpoint-tablet) {
    .dots {
        bottom: 5vh !important;
    }

    .carouselContainer {
        display: block;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .dots {
        bottom: 16vh !important;
    }
}

@media (pointer: coarse) {
    .dots {
        display: none !important;
    }
}
