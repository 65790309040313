@use 'scss/index' as *;

.container {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    overflow: hidden;
    height: 100%;
}

.heading {
    padding: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    background: $gray-gradient;
}

.list {
    list-style-type: none;
    overflow: auto;
    height: calc(100% - 77px);
    max-height: 100vh;
}

.noNews {
    padding: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
