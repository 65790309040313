@use 'scss/index' as *;

.contactSection {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/contact-bg.png');
    // background-image: url('../../../assets/contact-bg.png');
    background-position: center;
    height: 289px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.title {
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;
}

.button {
    text-transform: none;
}
