@use 'scss/index' as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.list {
    list-style-type: none;
    display: grid;
    gap: 2rem;
    justify-items: center;
}

.noEvents {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
}

@media screen and (min-width: $breakpoint-desktop) {
    .list {
        gap: 4rem;
    }
}
