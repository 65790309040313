@use 'scss/index' as *;

$imagePreviewSize: 125px;

@media not screen and (hover: none) and (pointer: coarse) {
    .mediaCarousel {
        scrollbar-width: auto;
        scrollbar-color: $black inherit;

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: inherit;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($black, 0.9);
            border-radius: $border-radius;
            border: 3px solid $white;
            transition: $transition-default;

            &:hover {
                background-color: rgba($black, 0.95);
            }

            &:active {
                background-color: $black;
            }
        }
    }
}

.productMedia {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 0.5rem;
    height: 100%;
}

.imageContainer {
    height: 400px;
    border: 1px solid $gray;
    background: white;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
    cursor: pointer;
}

.mediaCarouselContainer {
    overflow: hidden;
}

.mediaCarousel {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    height: calc($imagePreviewSize + 1.75rem);
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0.5rem 0;
}

.carouselImage,
.carouselVideo {
    height: $imagePreviewSize;
    width: $imagePreviewSize;
    border: 1px solid $gray;
    opacity: 0.7;
    transition: $transition-default;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.carouselImage {
    object-fit: cover;

    &.selected {
        opacity: 1;
    }
}

.carouselVideo {
    background: $gray-gradient;
    display: grid;
    align-items: center;
    justify-items: center;
}

.imageNavigationContainerRight,
.imageNavigationContainerLeft {
    height: 50%;
    padding: 0 0.5rem;
    background-color: none;
    display: grid;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        opacity: 1;
        background-color: rgba($gray, 0.2);
    }
}

.imageNavigationContainerLeft {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.imageNavigationContainerRight {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.imageArrow {
    font-size: 1.5rem;
    color: $white;
    background: $black;
    padding: 0.5rem;
    border-radius: 50%;
}

.modalImageBackground,
.modalVideo {
    box-shadow: $box-shadow;
    background: #fff;
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalImageBackground {
    width: 100%;
    height: 100vh;
    display: grid;
    justify-items: center;
    align-items: center;
}

.modalImage {
    max-height: 90vh;
    outline: none;

    &::selection {
        color: none;
        background: none;
    }
    /* For Mozilla Firefox */
    &::-moz-selection {
        color: none;
        background: none;
    }
}

.modalImageClose {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 3rem;
    color: $black;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.modalVideo {
    max-height: 90vh;
    max-width: 80vw;
}

@media screen and (min-width: $breakpoint-tablet) {
    .image {
        pointer-events: all;
    }
}
