@use 'scss/index' as *;

.container {
    box-shadow: $box-shadow;
    color: $black;
    padding: 1rem;
    border-radius: $border-radius;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-width: 300px;
    // transition: $transition-default;
    border: 1px solid $gray;

    // &:hover,
    // &:active {
    //     transform: scale(1.05);
    // }
}

.textContainer {
    display: grid;
    gap: 1.5rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.imageContainer {
    grid-row: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-height: 300px;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        grid-template-columns: 1fr minmax(auto, 500px);
        max-width: 1000px;
        padding: 2rem;

        &.noImage {
            grid-template-columns: 1fr;
        }
    }

    .title {
        font-size: 1.5rem;
    }

    .textContainer {
        grid-column: 1;
        grid-row: 1;
    }
}
