@use 'scss/index' as *;

.container {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 1.5rem;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        padding: 2rem;
    }
}
