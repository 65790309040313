@use 'scss/index.scss' as *;

@mixin disabled {
    cursor: auto;
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    &:hover {
        box-shadow: none;
    }
}

.button {
    width: fit-content;
    color: $white;
    padding: 0.5rem 2rem;
    border-radius: 22px;
    position: relative;
    transition: $transition-default;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1rem;
    white-space: nowrap;
    cursor: pointer;
    font-family: inherit;
    line-height: 1.75;
    background: transparent;

    &.fullWidth {
        width: 100%;
    }

    &.black.contained {
        background: $black;

        &:hover {
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
            background: hsl(240, 15%, 30%);
        }

        &.disabled {
            @include disabled;
        }
    }

    &.blue-gradient.contained {
        background: $blue-gradient-vertical;

        &:hover {
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
        }

        &.disabled {
            @include disabled;
        }
    }

    &.white.outlined {
        border: 2px solid $white;

        &:hover {
            background: rgba($white, 0.05);
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
        }

        &.disabled {
            @include disabled;
        }
    }

    &.blue.outlined {
        border: 2px solid $blue;
        color: $blue;

        &:hover {
            background: rgba($blue, 0.05);
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
        }

        &.disabled {
            @include disabled;
        }
    }

    &.blue.contained {
        background: $blue;

        &:hover {
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
                0px 1px 5px 0px rgb(0 0 0 / 12%);
            background: hsl(198, 100%, 47%);
        }

        &.disabled {
            @include disabled;
        }
    }
}
