@use 'scss/index.scss' as *;

$navbar-height: 100px;

.darkBackground {
    width: 100%;
    height: 100vh;
    background: rgba($black, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition-default;

    &.active {
        display: block;
        opacity: 1;
        visibility: visible;
    }
}

.mobileMenu {
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    background: $white;
    position: fixed;
    right: 0;
    top: 0;
    transition: $transition-default;
    transform: translateX(105%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: $z-index-fixed;
    box-shadow: -5px 0 5px 0 rgba($black, 0.2);

    &.active {
        transform: translateX(0);
    }
}

.mobileMenuTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $navbar-height;
}

.mobileMenuTitle {
    font-size: 1.5rem;
    padding: 1rem;
    font-weight: 500;
}

.mobileClose {
    font-size: 2rem;
    padding: 1rem;
    color: $black;
    width: 24px;
    cursor: pointer;
    transition: $transition-default;

    &:hover {
        color: $blue;
    }
}

.mobileLinksList {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: $black;
    width: 100%;
    padding-bottom: 1rem;
}

.mobileLink {
    padding: 1rem;
    color: $black;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: $transition-default;
    width: 100%;

    &:hover {
        color: $blue;
    }

    &.active {
        color: $blue;
    }
}

.mobileSocials {
    width: 100%;
    border-top: 6px solid $black;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.socialIcon {
    font-size: 2rem;
    color: $black;
    padding: 0.5rem;
    transition: $transition-default;
    cursor: pointer;

    &:hover {
        color: $blue;
    }
}

@media screen and (min-width: $breakpoint-mobile) {
    .mobileMenu {
        width: fit-content;
        min-width: 300px;
    }

    .mobileMenuTop {
        padding: 0 1rem;
    }

    .mobileLink {
        padding: 1rem 2rem;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .mobileBars,
    .mobileMenu {
        display: none;
    }

    .darkBackground {
        display: none;
    }
}
