@use 'scss/index' as *;

.card {
    position: relative;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    background: white;

    display: grid;
    grid-template-rows: 250px auto;
    justify-items: center;
    gap: 1rem;
    color: $black;
}

.imageContainer {
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content {
    padding: 1rem;
    display: grid;
    gap: 1rem;
}

.title {
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    transition: $transition-default;
}

.description {
    color: rgba($black, 0.9);
}

.buttonsContainer {
    align-self: flex-end;
}
