@use 'scss/index.scss' as *;

.header {
    display: none;
}

@media screen and (min-width: $breakpoint-desktop) {
    .header {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 55px;
        padding: 1rem 2rem;
        border-bottom: 1px solid rgba($black, 0.5);
        color: $black;
        background: $white;
        z-index: $z-index-fixed + 1;
    }

    .rightContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .contacts {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .contactContainer,
    .socials {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .contactContainer {
        transition: $transition-default;

        &:hover {
            color: $blue;
        }
    }

    .icon {
        font-size: 1.25rem;
        padding: 0.5rem;
    }

    .phone,
    .email {
        color: inherit;
    }
}
