@use 'scss/index.scss' as *;

.container {
    display: flex;
    flex-direction: column;
    // grid-template-rows: auto 1fr;
    gap: 1rem;
}

.title {
    align-self: flex-start;
    &::after {
        margin-top: 0.25rem;
        content: '';
        display: block;
        background: linear-gradient(to right, #0072bc, #80c4ff);
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
    }
}

.personnelList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    // flex-direction: column;
    gap: 1.5rem;
}
