@use 'scss/index' as *;

.container {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    color: $black;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: scale(0.95) rotate(45deg);
        border-radius: inherit;
        border: 24px solid transparent;
        border-top: 24px solid $blue-dark;
        transition: inherit;
        -webkit-transition: inherit;
    }
}

.image {
    width: 100%;
    height: 100%;
}
