@use 'scss/index.scss' as *;

.cardContainer {
    height: fit-content;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    justify-items: center;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    border: 1px solid $gray;
    border-radius: $border-radius;
    transition: $transition-default;
    background: white;

    &:hover {
        transform: scale(1.05);
    }
}

.image {
    height: 175px;
    width: auto;
    max-width: 100%;
}

.content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.name {
    font-weight: 500;
    font-size: 1.1rem;
    transition: $transition-default;
}

.contentBottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.prices {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.priceOriginal,
.priceNew {
    letter-spacing: 1px;
    white-space: nowrap;
}

.priceOriginal {
    text-decoration: line-through;
    color: #6a6f73;
    font-weight: 400;
}

.priceNew {
    font-size: 1.5rem;
    font-weight: 600;
}

.noVAT {
    font-size: 1rem;
    align-self: flex-end;
}
