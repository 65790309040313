@use 'scss/index.scss' as *;

$header-padding-mobile: 2rem 1rem;
$header-padding-desktop: 3rem 2rem;

.container {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: calc(80vh - 90px - 55px);
}

.headerContainer {
    width: 100%;
}

.header {
    background: $blue-to-transparent-gradient-vertical;
    color: $white;
    box-shadow: 0 2px 4px 0 rgb(48 48 65 / 20%);
    position: relative;
}

.headerContent {
    padding: $header-padding-mobile;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.textContainer {
    display: grid;
    gap: 0.5rem;
}

.title {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1px;
}

.description {
    font-size: 1.5rem;
}

.headerBgImage {
    display: block;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.content {
    padding: $header-padding-mobile;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
    height: 100%;
}

@media screen and (min-width: $breakpoint-tablet) {
    .headerContent {
        padding: $header-padding-desktop;
        max-width: 1400px;
        margin: auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .content {
        padding: 2rem;
        max-width: 1400px;
        margin: auto;
    }

    .content.fullWidth {
        max-width: none;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .headerBgImage {
        background-size: contain;
    }
}
