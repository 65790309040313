@use 'scss/index' as *;

.container {
    display: grid;
    align-items: center;
    background: $black;
    color: $white;
    width: 100%;
    padding: 2rem;
}

.content {
    margin: auto;
    display: grid;
    justify-items: center;
    gap: 4rem;
    align-items: center;
    justify-content: center;
}

.smallImage {
    max-width: 100%;
}

.textContainer {
    display: grid;
    gap: 2rem;
}

.description {
    font-size: 1.25rem;
    line-height: 30px;
}

@media screen and (min-width: $breakpoint-tablet) {
    .container {
        height: 565px;
    }

    .content {
        grid-template-columns: auto 1fr;
        max-width: 1400px;
        gap: 8rem;
    }
}
