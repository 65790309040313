@use 'scss/index.scss' as *;

.container {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    row-gap: 2rem;
}

.contactItemDescription {
    color: rgba($black, 0.8);
    line-height: 1.5rem;
}

.map {
    grid-row: 1;
    width: 100%;
    min-height: 350px;
    height: 100%;
    border: 1px solid $gray;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.contactList {
    grid-row: 2;
    display: grid;
    gap: 1rem;
    list-style: none;
    align-self: center;
}

.contactListHeader {
    grid-column: 1 / 3;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.contactListItem {
    display: grid;
    height: fit-content;
    gap: 0.5rem;
}

.contactItemTitle {
    font-weight: 500;
    font-size: 20px;
}

.personnelContainer {
    grid-column: 1 / 3;
}

.personnelTypeList {
    list-style-type: none;
    display: grid;
    gap: 2rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .container {
        grid-template-columns: 1fr 1fr;
        column-gap: 4rem;
    }

    .contactList {
        grid-row: 1;
        grid-template-columns: 1fr 1fr;
        column-gap: 4rem;
    }

    .personnelTypeList {
        column-gap: 4rem;
        row-gap: 2rem;
        // grid-template-columns: 1fr 1fr;
    }
}
