@use 'scss/index' as *;

.container {
    display: grid;
    gap: 1rem;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 1.5rem;
}

.title {
    margin-bottom: 2rem;
}

.subtitle {
    font-weight: 500;
    font-size: 1.5rem;
}

.secondaryText {
    color: rgba($black, 0.8);
    line-height: 1.5rem;
}

.link {
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}
