@use 'scss/index' as *;

.container {
    display: grid;
    gap: 2rem;
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.actionButton {
    grid-row: 1;
    width: 100%;
    white-space: nowrap;
}

.mainContent {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    height: 100%;
}

.heading {
    padding: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    background: $gray-gradient;
    border-radius: $border-radius $border-radius 0 0;
}

.textContent {
    display: grid;
    gap: 1rem;
    padding: 2rem;
}

.subtitle {
    font-weight: 500;
    font-size: 1.5rem;
}

.secondaryText {
    color: rgba($black, 0.8);
    line-height: 1.5rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .buttonsContainer {
        flex-wrap: nowrap;
    }

    .actionButton {
        width: fit-content;
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        display: grid;
        grid-template-columns: 1fr 350px;
    }
}
