@use 'scss/index.scss' as *;

.container {
    display: none;
}

@media screen and (min-width: $breakpoint-desktop) {
    .container {
        grid-row: 1 / 3;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }
}
