@use 'scss/index' as *;

.productInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.productInfoText {
    display: grid;
    gap: 1rem;
}

.productCategory {
    transition: $transition-default;
    color: inherit;
    width: fit-content;

    &:hover {
        cursor: pointer;
        color: $blue;
    }
}

.badgesContainer {
    display: flex;
    gap: 0.5rem;
}

.productName {
    font-size: 2rem;
    font-weight: 500;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background: $blue;
        left: -0.5rem;
        position: absolute;
    }
}

.productDescription {
    line-height: 1.4rem;
}

.buttons {
    display: flex;
    flex-direction: column;
    column-gap: 0.5rem;
    row-gap: 1rem;
}

.pricesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.price {
    font-size: 1.2rem;
}

.priceGross {
    color: $blue;
    font-weight: 600;
    font-size: 1.5rem;
}

@media screen and (min-width: $breakpoint-tablet) {
    .productName {
        &::before {
            left: -0.75rem;
        }
    }
}

@media screen and (min-width: $breakpoint-desktop) {
    .productInfo {
        padding: 1rem;
        gap: 1.5rem;
    }
}
