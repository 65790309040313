// Main colors
$black: #303041;
$blue: #0096d6;
$white: #f8fcff;
$gray: #e7e6eb;
// $gray: #f4f5f8;

// New Colors
$blue-dark: #0060af;
$blue-light: #7fc3fe;
$white-accent: rgb(244, 247, 252);
$gray-accent: #eef6fc;

// Gradients
$blue-gradient-vertical: linear-gradient(to right, #0072bc, #80c4ff);
$blue-gradient-bottom: linear-gradient(to bottom, #0072bc, #80c4ff);
$blue-to-transparent-gradient-vertical: linear-gradient(
    to right,
    #0072bc,
    hsla(205, 70%, 56%, 1),
    hsla(208, 100%, 75%, 0.25)
);
$gray-gradient: linear-gradient(to right, $gray, #dee2e7);
$main-gradient: $blue-gradient-vertical;

// Breakpoints
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1100px;

// z-index
$z-index-dropdown: 1000;
$z-index-sticky: 1100;
$z-index-fixed: 1200;
$z-index-modal-backdrop: 1300;
$z-index-modal: 1400;
$z-index-popup: 1500;
$z-index-tooltip: 1600;

// Other
$border-radius: 8px;
$transition-default: 0.2s ease-in-out;
$box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

$font-primary: $black;
